@font-face {
  font-family: 'Calibri';
  src: local('Calibri'),
    url('fonts/Calibri/dbcfebeb-81b6-4dd3-912a-c4fc1bb8b8dc.woff2') format('woff2'),
    url('fonts/Calibri/e7769402-c907-4b00-b07b-719187adf68a.woff') format('woff');
}
@font-face {
  font-family: 'Calibri Bold';
  src: local('Calibri Bold'),
    url('fonts/Calibri\ Bold/3ca076a2-34ad-4c48-a029-02536a509f22.woff2') format('woff2'),
    url('fonts/Calibri\ Bold/bc67b9af-646e-4b42-854e-ff3981151dea.woff') format('woff');
}
@font-face {
  font-family: 'Calibri Light';
  src: local('Calibri Light'),
    url('fonts/Calibri\ Light/3a9dc726-d61e-48d0-86ba-9548f35b5625.woff2') format('woff2'),
    url('fonts/Calibri\ Light/de3d8ed1-44f0-4110-88aa-6b53605d358a.woff') format('woff');
}
html {
  font-size: 62.5%;
}
body {
  font-size: 2em;
  margin: 0;
  background-image: url('./GreenSoftwareExpertyzer-Headerbild E1.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  font-family: Calibri Light, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  font-weight: 200;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4;
  margin: 30px 0 20px;
}

h1 {
  font-weight: 200;
  font-size: 50px;
}
h2 {
  font-weight: 200;
  font-size: 40px;
}
h3 {
  font-family: Calibri Bold;
  font-weight: 600;
  font-size: 30px;
}

@media (max-width: 1024px) {
  body {
    font-size: 1.8em;
  }
  h1 {
    font-size: 1.8em;
  }
  h2 {
    font-size: 1.75em;
  }
  h3 {
    font-size: 1.5em;
  }
}

@media (max-width: 959px) {
  h1 {
    font-size: 1.8em;
  }
  h2 {
    font-size: 1.75em;
  }
  h3 {
    font-size: 1.5em;
  }
  body {
    font-size: 1.8em;
  }
}

@media (max-width: 767px) {
  body {
    font-size: 1.7em;
  }
}
